<template>
	<article class="about">
		<div class="about__image">
			<transition name="fade" mode="out-in">
				<img
					:src="$imgUrl(`about/${aboutArray[sliderIndex].image}`)"
					alt="image"
					:key="aboutArray[sliderIndex].image"
				/>
			</transition>
		</div>

		<div class="about__divider" />
		<div class="about__slider">
			<slider
				animation="fade"
				:autoplay="false"
				:touch="false"
				v-model="sliderIndex"
				@next="changeIndex"
				@previous="changeIndex"
			>
				<slider-item v-for="item in aboutArray" :key="item.image">
					<div class="slider__item" :key="item.id">
						<div
							class="slider__image"
							:style="{
								'background-image': `url(${$imgUrl(
									`about/${item.image}`
								)})`
							}"
							:key="item.image"
						/>
						<div v-if="item.id === 4" class="slider__cert">
							<a
								href="https://cms.passivehouse.com/de/training/directory/designers/detail/p18064"
							>
								<img
									:src="$imgUrl('contact/Passive_logo_L.png')"
									:alt="$t('contact.passive-house-designer')"
								/>
								<p class="text-bold">
									{{ $t('contact.phi-title') }}
								</p>
								{{ $t('contact.phi') }}
							</a>
							<a
								href="https://www.usgbc.org/people/tomasz-piwinski/0011637302"
							>
								<img
									:src="$imgUrl('contact/LEED_GA_L.png')"
									:alt="$t('contact.leed-green-associate')"
								/>
								<p class="text-bold">
									{{ $t('contact.leed-green-associate') }}
								</p>
								{{ $t('contact.leed-green-title-1') }}<br />
								{{ $t('contact.leed-green-title-2') }}
							</a>
						</div>
						<p
							v-for="(text, i) in item.texts"
							class="slider__paragraph"
							:key="i + item.image"
						>
							{{ $t(`about.slider.${item.id}.text.${text}`) }}
						</p>
					</div>
				</slider-item>
			</slider>
			<div class="slider__title">
				<transition name="fade" mode="out-in">
					<h3 class="slider__heading" :key="sliderIndex">
						{{ $t(`about.slider.${sliderIndex}.title`) }}
					</h3>
				</transition>
			</div>

			<transition name="fade" mode="out-in">
				<div v-if="sliderIndex === 1" class="about__image-logo">
					<img :src="$imgUrl(`about/EQsygnet.svg`)" alt="image" />
				</div>
			</transition>
		</div>
	</article>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'

export default {
	components: {
		Slider,
		SliderItem
	},
	data: () => ({
		aboutArray: [
			{
				id: 0,
				image: 'IMG_3593a-bg.jpg',
				texts: [0, 1]
			},
			{
				id: 1,
				image: 'IMG-01-forrest-architecture-trees-mood-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 2,
				image:
					'IMG-03-sand-architecture-dessert-dunes-mood-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 3,
				image:
					'IMG-02-seaside-architecture-see-mood-water-iceland-raw-design.jpg',
				texts: [0, 1]
			},
			{
				id: 4,
				image: 'IMG_05.jpg',
				texts: [0, 1, 2]
			}
		],
		sliderIndex: 0
	}),
	methods: {
		changeIndex({ next }) {
			this.sliderIndex = next
		},
		goToLastSlide() {
			this.sliderIndex = this.aboutArray.length - 1
		}
	},
	mounted() {
		if (localStorage.getItem('navigateToLastSlide') === 'true') {
			localStorage.removeItem('navigateToLastSlide')
			this.goToLastSlide()
		}
	}
}
</script>
