import { WORKSHOP, LIBRARY, DOCTORATE } from '@/constants'

export default {
	namespaced: true,
	state: {
		data: [
			{
				id: 0,
				type: WORKSHOP,
				labels: [
					'school',
					'name',
					'department',
					'course',
					'leader',
					'location'
				],
				texts: [0, 1, 2, 3],
				assets: [
					{
						id: '01',
						text: 'Seweryn Trzyna, Kamil Ziółkowski',
						cover: '01_Seweryn_Trzyna_Kamil_Ziolkowski_ikona.jpg',
						assets: [
							'01_trzyna_ziolkowski.jpg',
							'02_trzyna_ziolkowski.jpg',
							'03_trzyna_ziolkowski.jpg'
						]
					},
					{
						id: '02',
						text: 'Dagna Dembiecka',
						cover: '02_Dagna_Dembiecka_ikona.jpg',
						assets: [
							'Dagna_Dembiecka_1.jpg',
							'Dagna_Dembiecka_2.jpg'
						]
					},
					{
						id: '03',
						text: 'Miłosz Andryszewski',
						cover: '03_Milosz_Andryszewski_ikona.jpg',
						assets: [
							'Plansze_morena_2021.jpg',
							'Plansze_morena_2021_2.jpg'
						]
					},
					{
						id: '04',
						text: 'Katarzyna Krawczyk, Zuzanna Kłos',
						cover: '04_Katarzyna_Krawczyk_Zuzanna_Klos_ikona.jpg',
						assets: [
							'1_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'2_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'3_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'4_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'5_Katarzyna_Krawczyk_Zuzanna_Klos.jpg'
						]
					},
					{
						id: '05',
						text: 'Joanna Lipnicka, Eryk Szczepański',
						cover: '05_Joanna_Lipnicka_Eryk_Szczepanski_ikona.jpg',
						assets: [
							'01_Joanna_Lipnicka_Eryk_Szczepanski.jpg',
							'02_Joanna_Lipnicka_Eryk_Szczepanski.jpg'
						],
						prize: true,
						secondPrize: true
					},
					{
						id: '06',
						text: 'Iga Stanisławska, Michał Orlikowski',
						cover:
							'06_Iga_Stanislawska_Michal_Orlikowski_ikona.jpg',
						assets: [
							'1_Iga_Stanislawska_Michal_Orlikowski.jpg',
							'2_Iga_Stanislawska_Michal_Orlikowski.jpg'
						]
					},
					{
						id: '07',
						text: 'Adrianna Nowakowska, Sebastian Jędrzejewski',
						cover:
							'07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_ikona.jpg',
						assets: [
							'07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_1.jpg',
							'07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_2.jpg'
						]
					},
					{
						id: '08',
						text: 'Aleksandra Biernacka',
						cover: '08_Aleksandra_Biernacka_ikona.jpg',
						assets: [
							'08_Aleksandra_Biernacka_1.jpg',
							'08_Aleksandra_Biernacka_2.jpg',
							'08_Aleksandra_Biernacka_3.jpg'
						],
						additional: true
					},
					{
						id: '09',
						text: 'Kornelia Traut, Sebastian Jędrzejewski',
						cover:
							'Kornelia_Traut_Sebastian_Jedrzejewski_ikona.jpg',
						assets: [
							'Kornelia_Traut_Sebastian Jedrzejewski_1.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_2.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_3.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_4.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_5.png'
						],
						prize: true,
						secondPrize: true
					},
					{
						id: '10',
						text: 'Marta Staniszewska, Martyna Domeradzka',
						cover:
							'Marta_Staniszewska_Martyna_Domaradzka_ikona.png',
						assets: [
							'Marta_Staniszewska_Martyna_Domaradzka_1.png',
							'Marta_Staniszewska_Martyna_Domaradzka_2.png',
							'Marta_Staniszewska_Martyna_Domaradzka_3.png'
						],
						prize: true
					},
					{
						id: '11',
						text: 'Jędrzej Jedwabny',
						cover: 'Jędrzej_Jedwabny_ikona.png',
						assets: [
							'Jędrzej Jedwabny_1.png',
							'Jędrzej Jedwabny_2.png',
							'Jędrzej Jedwabny_3.png',
							'Jędrzej Jedwabny_4.png'
						]
					},
					{
						id: '12',
						text: 'Malwina Bleja, Klaudia Skibińska',
						cover: 'Malwina_Bleja_Klaudia_Skibińska_ikona.png',
						assets: [
							'Malwina Bleja_Klaudia Skibińska_1.png',
							'Malwina Bleja_Klaudia Skibińska_2.png',
							'Malwina Bleja_Klaudia Skibińska_3.png',
							'Malwina Bleja_Klaudia Skibińska_4.png'
						]
					},
					{
						id: '13',
						text: 'Sebastian Jędrzejewski',
						cover: 'sebastian_jędrzejewski_ikona.jpg',
						assets: [
							'Sebastian Jędrzejewski_1.png',
							'Sebastian Jędrzejewski_2.png',
							'Sebastian Jędrzejewski_3.png',
							'Sebastian Jędrzejewski_4.png',
							'Sebastian Jędrzejewski_5.png',
							'Sebastian Jędrzejewski_6.png',
							'Sebastian Jędrzejewski_7.png',
							'Sebastian Jędrzejewski_8.png'
						],
						additional: true
					},
					{
						id: '14',
						text: 'Klara Kondracka',
						cover: 'Klara_Kondracka_ikona.png',
						assets: [
							'Klara Kondracka_1.png',
							'Klara Kondracka_2.png',
							'Klara Kondracka_3.png',
							'Klara Kondracka_4.png',
							'Klara Kondracka_5.png'
						],
						additional: true,
						prize: true,
						secondPrize: true
					},
					{
						id: '15',
						text: 'Małgorzata Milancej, Jagna Przybylska',
						cover:
							'15_Malgorzata-Milancej_Jagna-Przybylska_ikona.jpg',
						assets: ['15_1.jpg', '15_2.jpg', '15_3.jpg', '15_4.jpg']
					},
					{
						id: '16',
						text: 'Jędrzej Jedwabny',
						cover: '16_Jedrzej-Jedwabny.jpg',
						assets: ['16_1.jpg', '16_2.jpg', '16_3.jpg', '16_4.jpg']
					},
					{
						id: '17',
						text: 'Agata Kotlicka, Jakub Kaczyński',
						cover: '17_Agata-Kotlicka_Jakub-Kaczynski.jpg',
						assets: ['17_1.jpg', '17_2.jpg', '17_3.jpg']
					},
					{
						id: '18',
						text: 'Weronika Sabok, Anna Ramos',
						cover: '18_Weronika-Potok_Anna-Ramos.jpg',
						assets: ['18_1.jpg', '18_2.jpg']
					},
					{
						id: '19',
						text: 'Weronika Sabok - Rzepka',
						cover: '19_Weronika-Potok.jpg',
						assets: [
							'19_1.jpg',
							'19_2.jpg',
							'19_3.jpg',
							'19_4.jpg',
							'19_5.jpg',
							'19_6.jpg',
							'19_7.jpg',
							'19_8.jpg'
						],
						additional: true,
						prize: true,
						secondPrize: true
					},
					{
						id: '20',
						text: 'Agata Marciniak, Aleksandra Mucha',
						cover: '20_Agata-Marciniak_Aleksandra-Mucha.jpg',
						assets: [
							'20_1.png',
							'20_2.png',
							'20_3.png',
							'20_4.png',
							'20_5.png',
							'20_6.png',
							'20_7.png',
							'20_8.png',
							'20_9.png',
							'20_10.png',
							'20_11.png',
							'20_12.png'
						],
						additional: true
					},
					{
						id: '21',
						text: 'Jędrzej Jedwabny',
						cover: '21_Jedrzej-Jedwabny_2.jpg',
						assets: [
							'21_1.png',
							'21_2.png',
							'21_3.png',
							'21_4.png',
							'21_5.png',
							'21_6.png',
							'21_7.png',
							'21_8.png',
							'21_9.png',
							'21_10.png',
							'21_11.png',
							'21_12.png'
						],
						additional: true
					},
					{
						id: '22',
						text: 'Aleksandra Bieszka, Maria Pielach',
						cover: '22_Aleksandra-Bieszka_Maria-Pielach.png',
						assets: [
							'22_1.png',
							'22_2.png',
							'22_3.png',
							'22_4.png',
							'22_5.png',
							'22_6.png',
							'22_7.png',
							'22_8.png',
							'22_9.png',
							'22_10.png',
							'22_11.png',
							'22_12.png'
						],
						additional: true,
						prize: true
					},
					{
						id: '23',
						text: 'Agata Marciniak',
						cover: '23_Agata-Marciniak.jpg',
						assets: [
							'23_1.jpg',
							'23_2.jpg',
							'23_3.jpg',
							'23_4.jpg',
							'23_5.jpg',
							'23_6.jpg'
						],
						additional: true,
						prize: true,
						secondPrize: true
					}
				]
			},
			{
				id: 1,
				type: LIBRARY,
				labels: ['school', 'name', 'department', 'leader', 'location'],
				texts: [0, 1, 2, 3],
				assets: [
					{
						id: 'lib',
						cover: '1.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '2.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '3.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '4.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '5.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '6.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '7.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '8.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '9.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '10.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '11.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '12.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '13.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '14.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '15.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '16.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '17.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '18.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '19.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '20.jpg',
						group: true
					}
				]
			},
			{
				id: 2,
				type: DOCTORATE,
				labels: [
					'school',
					'department',
					'work-title',
					'promoter',
					'reviewer1',
					'reviewer2',
					'year'
				],
				texts: [0, 1, 2, { type: 'link', url: 3 }],
				assets: [
					{
						id: 'doc',
						cover: 'EQ-edit.gif',
						group: true,
						link:
							'https://www.youtube.com/watch?v=DQSoS7ma4w0&ab_channel=JG'
					}
				]
			}
		]
	},
	getters: {
		data: (state) => state.data
	},
	mutations: {},
	actions: {}
}
