<template>
	<footer class="footer__wrap">
		<div class="footer__image">
			<img :src="$imgUrl('eq-logotyp.png')" />
			<template v-if="isCertVisible">
				<a @click.prevent="navigateToAboutSlide">
					<img
						:src="$imgUrl('contact/Passive_logo_L.png')"
						:alt="$t('contact.passive-house-designer')"
					/>
				</a>
				<a @click.prevent="navigateToAboutSlide">
					<img
						:src="$imgUrl('contact/LEED_GA_L.png')"
						:alt="$t('contact.leed-green-associate')"
					/>
				</a>
			</template>
		</div>
		<div class="footer__copy">
			<p>{{ $t('footer.p1') }}</p>
			<p>
				{{ $t('footer.p2') }}
				<a :href="$t('external.cud')">
					{{ $t('footer.credits') }}
				</a>
			</p>
		</div>
	</footer>
</template>
<script>
import { includes } from 'lodash'
export default {
	computed: {
		isCertVisible: ({ $route }) =>
			!includes(['contact', 'contact-en'], $route.name)
	},
	methods: {
		navigateToAboutSlide() {
			localStorage.setItem('navigateToLastSlide', 'true')
			const redirection = this.$store.getters['settings/menu'](
				this.$i18n.locale
			).find(({ text }) => text === 'about')
			this.$router.push({ path: redirection.url })
		}
	}
}
</script>
